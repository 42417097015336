import React from "react";
import theme from "theme";
import { Theme, Text, Em, Strong, Box, Image, LinkBox, Section, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				STARGAZE EVENTS
			</title>
			<meta name={"description"} content={"Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність."} />
			<meta property={"og:title"} content={"STARGAZE EVENTS"} />
			<meta property={"og:description"} content={"Ми створюємо незабутні події, які залишають яскраві враження та дарують радість. Довірте нам свої ідеї, і ми перетворимо їх на реальність."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65801ab5867c86001f194a8a/images/17_85293.png?v=2023-12-18T10:15:37.785Z"} />
		</Helmet>
		<Components.HeaderNew box-shadow="--m" margin="0px 0px 5px 0px" xl-border-color="#fdfae6" xl-border-width={0} />
		<Section padding="80px 0 80px 0" lg-padding="56px 0 56px 0" sm-padding="32px 0 32px 0" background="#ffffff">
			<Box
				margin="0px 0px 32px 0px"
				md-margin="0px 0px 40px 0px"
				lg-margin="0px 0px 33px 0px"
				display="flex"
				flex-direction="column"
				align-items="center"
				lg-padding="15px 15px 15px 15px"
				sm-margin="0px 0px 10px 0px"
				justify-content="center"
			>
				<Text
					font="--headline1"
					color="--dark"
					text-align="center"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					margin="16px 0px 0px 0px"
				>
					Про нас
				</Text>
				<Text
					font="--lead"
					color="--darkL2"
					text-align="center"
					md-width="100%"
					max-width="600px"
				>
					<Strong>
						<Em>
							Знайомтесь з StarGaze Events
						</Em>
					</Strong>
					{"\n"}
				</Text>
			</Box>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				>
					<Image
						src="https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/Special-Events-Management.jpg?v=2024-06-20T19:52:03.660Z"
						border-radius="24px"
						max-width="100%"
						max-height="522px"
						width="100%"
						object-fit="cover"
						lg-max-height="392px"
						object-position="0% 30%"
						sm-max-height="213px"
						srcSet="https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Special-Events-Management.jpg?v=2024-06-20T19%3A52%3A03.660Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Special-Events-Management.jpg?v=2024-06-20T19%3A52%3A03.660Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Special-Events-Management.jpg?v=2024-06-20T19%3A52%3A03.660Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Special-Events-Management.jpg?v=2024-06-20T19%3A52%3A03.660Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Special-Events-Management.jpg?v=2024-06-20T19%3A52%3A03.660Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Special-Events-Management.jpg?v=2024-06-20T19%3A52%3A03.660Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/667472d28e2e8e00217d2c55/images/Special-Events-Management.jpg?v=2024-06-20T19%3A52%3A03.660Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 767px) 100vw,(max-width: 991px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					width="100%"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-border-width="0px"
					flex-direction="column"
					padding="16px 12px 16px 12px"
				>
					<Text font="--headline3" color="--dark" margin="1rem 0px 2rem 0px">
						Ми – команда професіоналів, яка спеціалізується на організації заходів будь-якої складності. Наш досвід та креативний підхід дозволяють створювати незабутні події, які дарують радість та яскраві враження. Ми віримо, що кожен захід має бути особливим, тому підходимо до кожного проекту з великою увагою та відповідальністю.
					</Text>
					<Text margin="0px 0px 0px 0px" font="--lead" color="--dark">
						Наша місія – створювати незабутні події, які залишають найкращі спогади. Ми прагнемо робити ваші мрії реальністю, перетворюючи звичайні дні на незабутні свята. Кожен захід – це можливість для нас проявити свій професіоналізм та креативність, щоб перевершити ваші очікування.
					</Text>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-wrap="wrap"
				width="100%"
				margin="0px 0px 16px 0px"
				justify-content="flex-start"
				md-margin="0px 0px 16px 0px"
			>
				<Box
					width="100%"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					md-width="100%"
					lg-width="100%"
				/>
			</LinkBox>
		</Section>
		<Section
			padding="120px 0 130px 0"
			background="linear-gradient(120deg,rgba(163, 103, 177, 0.29) 1%,rgba(57, 0, 0, 0.72) 100%) 0 0 no-repeat,#070f4f url(https://uploads.quarkly.io/667472d28e2e8e00217d2c55/images/home-page-13.jpg?v=2024-06-20T19:52:03.658Z) center/100% repeat scroll padding-box"
			lg-padding="80px 0 90px 0"
			quarkly-title="Stages/Steps-2"
			text-transform="uppercase"
		>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(2, 1fr)"
				grid-gap="40px 30px"
				md-grid-template-columns="1fr"
				md-grid-template-rows="auto"
				md-grid-gap="26px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="--headline3">
							<Strong font="--headline2">
								Наша команда
							</Strong>
							{"\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--lightD1">
							Команда StarGaze Events складається з талановитих та досвідчених фахівців у сфері організації заходів. Ми постійно вдосконалюємо свої навички та знання, щоб залишатися на передовій індустрії. Наші менеджери, декоратори, технічні спеціалісти та координатори завжди готові втілити ваші ідеї в життя.
						</Text>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					padding="0px 70px 0px 0px"
					lg-padding="0px 0 0px 0px"
				>
					<Box min-width="100px" min-height="100px" margin="0px 0px 0px 25px">
						<Text margin="0px 0px 15px 0px" border-color="--color-light" color="--light" font="--headline3">
							<Strong font="--headline2">
								Наші цінності
							</Strong>
							{"\n"}
						</Text>
						<Text margin="0px 0px 0px 0px" font="--base" color="--lightD1">
							Ми цінуємо професіоналізм, креативність та індивідуальний підхід. Кожен наш клієнт – це партнер, якому ми приділяємо максимум уваги. Ми прагнемо досягти найвищих стандартів якості у всіх аспектах нашої роботи, забезпечуючи бездоганне обслуговування та задоволення потреб клієнтів.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			text-align="center"
			background-color="--primary"
			color="--light"
			padding="80px 0"
			sm-padding="40px 0"
			background="--color-darkL1"
		>
			<Text as="h1" font="--headline2" md-font="--headline2" margin="10px 0 0 0">
				<Strong>
					Наші досягнення
				</Strong>
				{"\n"}
			</Text>
			<Text as="p" font="--headline3" margin="40px 0 20px 0">
				За роки роботи ми організували безліч успішних заходів, які отримали високі оцінки від наших клієнтів. Наші проекти включають корпоративні свята, весілля, ювілеї, дитячі дні народження та багато інших подій. Ми пишаємося своїми досягненнями та завжди раді новим викликам.
			</Text>
			<Link
				href="/about"
				padding="12px 24px 12px 24px"
				color="--light"
				background="--color-secondary"
				text-decoration-line="initial"
				font="--lead"
				border-radius="8px"
				margin="0px auto 0px auto"
				transition="background-color 0.2s ease-in-out 0s"
				hover-background="--color-orange"
				sm-margin="0px 0px 0px 0px"
				sm-text-align="center"
				hover-transition="background-color 0.2s ease-in-out 0s"
				max-width="350px"
			>
				Дізнатися більше
			</Link>
		</Section>
		<Components.NewFooter1>
			<Override slot="text" />
			<Override slot="link1">
				0674705787
			</Override>
			<Override slot="link2" />
		</Components.NewFooter1>
	</Theme>;
});